.hero {
	background-image: url(../../images/hero-background.svg);
	background-size: cover;
	color: #363636;
	height: 95vh;
	width: 100vw;
	position: relative;
	text-align: left;
}

.hero-arrow {
	-webkit-transform: scaleX(1.2) scaleY(2);
	    -ms-transform: scaleX(1.2) scaleY(2);
	        transform: scaleX(1.2) scaleY(2);
}

.hero-laptop-icon {
	position: absolute;
	z-index: 0;
	right: 17%;
	bottom: 15%;
	width: 25%;
}

.partners-container {
	background-color: #ffffff;
	text-align: center;
}

.partners-text {
	font-size: 1.3rem;
	padding: 5rem 2rem 1rem 2rem;
	color: #686868;
}

.partners-icons-container {
	padding: 0 10%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.partner-icon {
	margin: 1.5rem;
}

.partners-divider {
	width: 50%;
	border: none;
	border-bottom: 1px solid rgba(141, 141, 141, 1)
}

.learn-more {
	padding: 3rem 2rem 5rem 2rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.learn-more-2 {
	padding: 1rem 2rem 1rem 2rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.usecases-btn, .compare-btn {
	padding: 1rem 2rem;
	margin: 1rem;
}

.testimonial-container {
	text-align: center;
	background: -o-linear-gradient(359.94deg, #8BE6FF 15.29%, #37BAFF 92.88%);
	background: linear-gradient(90.06deg, #8BE6FF 15.29%, #37BAFF 92.88%);
	padding: 6rem 2rem 5rem 2rem;
}

.people-said-text {
	margin-bottom: 2rem;
	/* padding: 0 2rem 1.5rem 2rem; */
}

.testimonial-carousel-container {
	border-radius: 20px;
	background-color: #ffffff;
	width: 50%;            /* SET HIGHER WIDTHS FOR SMALLER SCREEN SIZES USING MEDIA QUERIES*/
	margin: 0 auto;
}

#goLeft {
	/* margin-left: 2rem; */
	/* border-top: 3rem solid;
	border-bottom: 3rem solid;
	border-left: 2rem solid;
	border-right: 2rem solid;
	border-color: transparent; */

}

#goRight {
	/* margin-right: 2rem; */
}

#goLeft, #goRight {
	border-top: 3rem solid;
	border-bottom: 3rem solid;
	border-left: 2rem solid;
	border-right: 2rem solid;
	border-color: transparent;
}

#goLeft:hover, #goRight:hover {
    cursor: pointer;
    -webkit-filter: invert(52%) sepia(0%) saturate(1920%) hue-rotate(336deg) brightness(91%) contrast(92%);
            filter: invert(52%) sepia(0%) saturate(1920%) hue-rotate(336deg) brightness(91%) contrast(92%);
}


.swiper-button-prev {
	background-image: url(../../images/left-arrow.svg);
}

.swiper-button-next {
	background-image: url(../../images/right-arrow.svg);
}

.swiper-button-next,
.swiper-button-prev {
	margin: 0 .7rem;
	color: transparent !important;
	background-size: cover;
	background-repeat: no-repeat;
	width: 1rem !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
	-webkit-filter: invert(52%) sepia(0%) saturate(1920%) hue-rotate(336deg) brightness(91%) contrast(92%);
	        filter: invert(52%) sepia(0%) saturate(1920%) hue-rotate(336deg) brightness(91%) contrast(92%);
}

.explore-features-container {
	text-align: center;
	padding: 4rem 5rem 6rem 5rem;
}

.explore-features {
	margin: auto;
	width: 80%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.explore-feature-box {
	margin: 1rem;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
}

.explore-feature-box-img-container {
	height: 10rem;
}

.explore-feature-box-img {
	width: 140px;
}

.plans-container {
	padding: 3rem 7.5%;
	padding-bottom: 4rem;
	background-color: #37BAFF;
	/* background-image: url(../../images/plans-background.svg);
	background-repeat: repeat;
	background-size: cover; */
}

@media (max-width: 960px) {
	.testimonial-carousel-container {
		width: 90%;
	}

	.hero-laptop-icon {
		display: none;
	}


}

.generic-text-block {
	padding: 3rem 15% 2rem 15%;
}