.login {
    padding: 3rem 14.6%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.login-half {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-bottom: 2rem;
}

.login-box-1 {
    padding-left: 1.5rem;
    padding-right: 15%;
    border-left: 5px solid #3CBDFF;
    /* height: 100%; */
}

.login-box-2 {
    background-color: #F9F9F9;
    padding: 1.5rem;
    margin-bottom: 1rem;  
    border-radius: 20px;
}

.loginwiki-input {
    display: block;
    width: calc(100% - 1.2rem);
    margin: .5rem 0;
    /* height: 2rem; */
    border-radius: 5px;
    border: 1px solid #8D8D8D;
    font-family: 'Avenir';
    padding:.6rem 1rem;
    outline: none;
}

.loginwiki-input:active, .loginwiki-input:focus {
    border-color: #37BAFF;
}

.login-subtext {
    font-size: .7rem;
    font-weight: 400;
    color: #8d8d8d;
}

.loginwiki-btn {
    width: 100%;
    padding: .7rem;
    font-family: 'Avenir';
    margin: 1.5rem 0;
}

.button-green {
    text-align: center;
    display: inline-block;
    width: calc(100% - 5rem);
	position: relative;
	text-decoration: none;
	background-color: #42904A;
	border: 2px solid #42904A;
	color: #ffffff;
	padding: .7rem 2.5rem;
	border-radius: 40px;
	font-size: 1rem;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	margin-right: 1rem;
    margin-top: 1.5rem;
}

.button-green:hover {
    opacity: .9;
}