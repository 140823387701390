/* COLORS */
.color-primary {
	color: #37baff;
}

.color-dark {
	color: #363636;
}

.color-white {
	color: #ffffff;
}

.color-black {
	color: #000000;
}

.color-quaternary {
	color: #005495;
}

/* BACKGROUNDS */
.bg-lightblue {
	background-color: #ECF8FF;
}

/* HEADINGS */
.heading-primary-light {
	font-size: 2.5rem;
	line-height: 3.42rem;
	font-weight: 900;
	color: #ffffff;
}

.heading-primary-dark {
	font-size: 2.5rem;
	line-height: 3.42rem;
	font-weight: 900;
	color: #363636;
}

.heading-secondary-light {
	font-size: 1.63rem;
	line-height: 2.18rem;
	font-weight: 900;
	color: #ffffff;
}

.heading-secondary-dark {
	font-size: 1.63rem;
	line-height: 2.18rem;
	font-weight: 900;
	color: #363636;
}

.heading-tertiary {
	font-size: 1.25rem;
	line-height: 1.68rem;
	font-weight: 900;
	color: #005495;
}

.heading-tertiary-light {
	font-size: 1.25rem;
	line-height: 1.68rem;
	font-weight: 900;
	color: #ffffff;
}

.subheading-primary-light {
	font-size: 1.13rem;
	line-height: 2rem;
	font-weight: 400;
	color: #ffffff;
}

.subheading-primary-dark {
	font-size: 1.13rem;
	line-height: 2rem;
	font-weight: 400;
	color: #363636;
}

.body-text {
	font-size: 1rem;
	line-height: 1.68rem;
	font-weight: 400;
}

.body-text-light {
	font-size: 1rem;
	line-height: 1.68rem;
	font-weight: 400;
	color: #ffffff;
}

.body-text-dark {
	font-size: 1rem;
	line-height: 1.68rem;
	font-weight: 400;
	color: #363636;
}

/* BACKGROUND SECTIONS */
.gradient-section {
	text-align: center;
	padding: 11rem 3rem 3rem 3rem;
	background: linear-gradient(
		89.85deg,
		#99f9ff -9.12%,
		#47d5ff 42.84%,
		#28aae7 85.9%
	);
}

.gradient-section-light {
	text-align: left;
	padding: 1.5rem 14.6%;
	padding-top: 8rem;
	background: linear-gradient(
		89.85deg,
		#5fb7e0 -9.12%,
		#64cdec 42.84%,
		#99f9ff 85.9%
	);
}

.gradient-section-dark {
	text-align: center;
	padding: 13rem 3rem 5rem 3rem;
	background: linear-gradient(
		89.85deg,
		#004d89 -9.12%,
		#3081c0 42.84%,
		#37baff 85.9%
	);
}

.white-section {
	text-align: center;
	padding: 10rem 3rem 3rem 3rem;
	background-color: #ffffff;
	color: #005495 !important;
}

.statement-banner {
	background-image: url(./images/statement-banner-background.svg);
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	padding: 5rem 20%;
}

.statement-banner-align-only {
	text-align: center;
	padding: 5rem 20%;
}

/* BACKGROUNDS */

/* BUTTONS  */
.button-primary {
	display: inline-block;
	position: relative;
	text-decoration: none;
	background-color: #0031dd;
	border: 2px solid #0031dd;
	color: #ffffff;
	padding: 1rem 2.5rem;
	border-radius: 40px;
	font-size: 1rem;
	transition: all 0.3s;
}

.button-primary:hover {
	opacity: 0.8;
	cursor: pointer;
	/* transform: translateY(-.4rem) scale(1.02); */
	/* box-shadow: 0 .5rem 1rem rgb(90, 90, 90); */
}

.button-primary-light {
	display: inline-block;
	position: relative;
	text-decoration: none;
	background-color: #ffffff;
	border: 2px solid #ffffff;
	color: #0031dd;
	padding: 1rem 2.5rem;
	border-radius: 40px;
	font-size: 1rem;
	transition: all 0.3s;
}

.button-primary-light:hover {
	/* color: #ffffff;
	background-color: #0031dd;
	border-color: #0031dd; */
	opacity: 0.8;
	cursor: pointer;
}

.button-secondary {
	display: inline-block;
	text-decoration: none;
	background-color: transparent;
	color: #0031dd;
	padding: 1rem 2.5rem;
	border: solid 2px #0031dd;
	border-radius: 40px;
	font-size: 1rem;
	transition: all 0.3s;
}

.button-secondary:hover {
	color: #ffffff;
	background-color: #0031dd;
	opacity: 0.8;
	cursor: pointer;
	/* border-color: #0028b8;
	color: #0028b8; */
	/* color: #0031dd; */
	/* background-color: #1044ff; */
	/* color: #37baff; */
	/* border-color: #37baff; */
}

.button-secondary-light {
	display: inline-block;
	text-decoration: none;
	background-color: transparent;
	color: #ffffff;
	padding: 1rem 2.5rem;
	border: solid 2px #ffffff;
	border-radius: 40px;
	font-size: 1rem;
	transition: all 0.3s;
}

.button-secondary-light:hover {
	color: #0031dd;
	background-color: #fff;
	opacity: 0.8;
	cursor: pointer;
}

.button-tertiary {
	display: inline-block;
	text-decoration: none;
	background-color: transparent;
	color: #0031dd;
	padding: 0.8rem 4rem;
	border: solid 4px #0031dd;
	border-radius: 30px;
	font-weight: 900;
	font-size: 1rem;
	transition: all 0.3s;
}

.button-tertiary:hover {
	background-color: #0031dd;
	color: #ffffff;
	cursor: pointer;
}

.button-quaternary {
	display: inline-block;
	text-decoration: none;
	background-color: transparent;
	color: #ffffff;
	padding: 0.8rem 4rem;
	border: solid 4px #ffffff;
	border-radius: 30px;
	font-weight: 900;
	font-size: 1rem;
	transition: all 0.3s;
}

.button-quaternary:hover {
	color: #383838;
	background-color: #ffffff;
	cursor: pointer;
}

.button-link {
	text-decoration: none;
	color: #2ab0f8;
}

.button-link:hover {
	text-decoration: underline;
	color: #1f7eb1;
	cursor: pointer;
}

.button-link-white {
	color: #fff;
	font-weight: 600;
}

.button-link-white:hover {
	color: rgb(228, 228, 228);
	cursor: pointer;
}

.button-link-blue {
	color: #0031dd;
}

.button-link-blue:hover {
	opacity: 0.8;
	cursor: pointer;
}

/* MISC */
.center-text {
	text-align: center;
}

.left-text {
	text-align: left;
}

.right-text {
	text-align: right;
}

.mg-bottom-small {
	margin-bottom: 2rem;
}

.mg-bottom-medium {
	margin-bottom: 3.5rem;
}

.mg-bottom-large {
	margin-bottom: 5rem;
}

.padding-sides-small {
	padding-left: 10%;
	padding-right: 10%;
}